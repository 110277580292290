'use client';

import {
  ApolloNextAppProvider,
  useSuspenseQuery,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { SuspenseContext } from '@nerdwallet/apollo/suspense-context';

import makeClient from '~/app/lib/apollo-client/client';

export function ApolloWrapper({ headers, cookies, children }: any) {
  return (
    <ApolloNextAppProvider makeClient={makeClient({ headers, cookies })}>
      <SuspenseContext.Provider
        value={{ maybeUseSuspenseQuery: useSuspenseQuery }}
      >
        {children}
      </SuspenseContext.Provider>
    </ApolloNextAppProvider>
  );
}
