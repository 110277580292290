'use client';

import React from 'react';

import { AnalyticsContext } from '@nerdwallet/analytics/react';
import analytics from '@nerdwallet/analytics';

import { AnalyticsProvider } from '~/app/lib/segment/AnalyticsProvider';

export function AnalyticsWrapper({ children }: any) {
  return (
    <AnalyticsProvider>
      <AnalyticsContext.Provider value={analytics}>
        {children}
      </AnalyticsContext.Provider>
    </AnalyticsProvider>
  );
}
