import React, { useMemo } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

import appConfig from '@nerdwallet/app-config';

import { AnalyticsContext, AnalyticsContextProps } from './AnalyticsContext';

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider = ({
  children,
}: AnalyticsProviderProps): JSX.Element => {
  const writeKey = appConfig?.SEGMENT?.WRITE_KEY ?? '';

  const value: AnalyticsContextProps = useMemo(
    () => ({
      analytics: writeKey
        ? AnalyticsBrowser.load(
            {
              writeKey: appConfig?.SEGMENT?.WRITE_KEY,
              cdnURL: appConfig?.SEGMENT?.CDN_URL,
            },
            {
              cookie: {
                domain: appConfig?.SEGMENT?.DOMAIN,
                maxage: 3650, //time in days when the cookie expires, standard is 10 years 3650 days, https://nerdwallet.atlassian.net/wiki/spaces/PEDD/pages/2614034657/Standard+Event+Identification+Conventions#4.-SDK-Initialization--%5B2023.06.06%5D
                secure: appConfig?.SEGMENT?.SECURE,
              },
              integrations: {
                'Segment.io': {
                  apiHost: appConfig?.SEGMENT?.API_HOST,
                  protocol: appConfig?.SEGMENT?.PROTOCOL,
                },
              },
            },
          )
        : null,
    }),
    [writeKey],
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
